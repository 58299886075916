import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import AnchorLink from "react-anchor-link-smooth-scroll";

import TocIcon from "../../img/toc_icon.svg";

import * as S from "./Toc.styled";

type TOC = {
  label: string;
  link: string;
}[];

const Toc = ({ toc }: { toc: TOC }) => {
  const { t } = useTranslation();

  const [isMobileToc, setIsMobileToc] = useState(false);

  const toggleMobileToc = useCallback(() => {
    setIsMobileToc(!isMobileToc);
  }, [setIsMobileToc, isMobileToc]);

  const closeMobileToc = useCallback(() => {
    setIsMobileToc(false);
  }, [setIsMobileToc, isMobileToc]);

  return (
    <>
      <S.MobileTocButon onClick={toggleMobileToc}>
        <img src={TocIcon} />
        <span>
          {t("cms.toc")}
        </span>
      </S.MobileTocButon>
      <S.Toc opened={isMobileToc}>
        <S.LabelWrapper>
          <h4>{t("cms.toc")}</h4>
        </S.LabelWrapper>
        {toc && toc.length && (<ol>
          <li onClick={closeMobileToc}>
            <AnchorLink offset='100' href={`#intro`} title={t("cms.intro")}>{t("cms.intro")}</AnchorLink>
          </li>
          {toc.map(item => (
            <li key={item.label} onClick={closeMobileToc}>
              <AnchorLink offset='60' href={`#${item.link}`} title={item.label}>{item.label}</AnchorLink>
            </li>
          ))}
        </ol>)}
      </S.Toc>
    </>
  )
}

export default Toc;