
import { graphql } from "gatsby"

import ArticlePage from "../components/cms/ArticlePage";

export default ArticlePage

export const pageQuery = graphql`
  query ArticleByID($id: String!) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        date(formatString: "DD.MM.YYYY")
        rawDate:date
        title
        description
        keywords,
        tags
        toc {
          link,
          label
        },
        sources {
          link,
          label
        },
        author,
        authorsTitle, 
        readingTime
      }
    }
  }
`