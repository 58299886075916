import React from "react"
import { PageProps } from "gatsby"
import { Helmet } from "react-helmet"
import { MDXProvider } from "@mdx-js/react"
import styled from "styled-components";

import Layout from "../layout/Layout"
import PageWrapper from "../layout/PageWrapper";
import SEO from "../SEO";
import MyArticleTable from "./MyArticleTable"
import MyArticleImg from "./MyArticleImg"
import MyArticleMore from "./MyArticleMore"

import Article from "./Article";
import Toc from "./Toc"

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    flex-direction: column;
  }
`;

const components = {
  img: MyArticleImg,
  More: MyArticleMore,
  table: MyArticleTable,
  h2: ({children}: any) => <h2>{children}</h2>,
  h3: ({children}: any) => <h3>{children}</h3>
};

type Props = {
  data: {
    mdx: {
      id: string;
      body: string;
      frontmatter: {
        author?: string;
        authorsTitle?: string;
        date?: string;
        sources: {
          link: string;
          label: string;
        }[],
        toc: {
          link: string;
          label: string;
        }[],
        tags: string[];
        title?: string;
        description?: string;
        keywords: string[];
        rawDate: string;
      }
    }
  };
} & PageProps;

const ArticlePage = (props: Props) => {
  const { title, description, rawDate, toc = [], sources = [], date, author, authorsTitle, tags } = props.data.mdx.frontmatter

  const keywords = React.useMemo(() => {
    const arr = props.data.mdx.frontmatter.keywords;
    return arr.length ? arr.join(", ") : "";
  }, [props.data.mdx.frontmatter]);

  return (
    <MDXProvider components={components}>
      <Layout location={props.location}>
        <Helmet
          bodyAttributes={{
            "type": "article",
          }}
        />
        <SEO
          title={title}
          description={description}
          keywords={keywords}
          date={rawDate}
        />
        <PageWrapper align="left">
          <Container>
            {toc && toc.length > 0 && (
              <Toc toc={toc} />
            )}
            <Article
              title={title}
              author={author}
              authorsTitle={authorsTitle}
              date={date}
              content={props.data.mdx.body} 
              sources={sources}
              tags={tags}
            />
          </Container>
        </PageWrapper>
      </Layout>
    </MDXProvider >
  )
}

export default ArticlePage;
