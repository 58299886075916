import styled from "styled-components";

export const MobileTocButon = styled.div`
  display: none;
  cursor: pointer;
  width: 100%;
  border: 1px solid ${props => props.theme.colors.border};
  border-radius: 8px;
  padding: 16px;
  margin: 0 0 20px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    display: flex;
  }

  img {
    margin-right: 10px;
  }
`;

export const Toc = styled.aside<{opened?: boolean}>`
  border-right: 1px solid ${props => props.theme.colors.border};
  position: relative;
  max-width: 260px;
  width: 100%;
  padding-bottom: 48px;
  padding-right: 48px;
  margin-right: 48px;
  position: sticky;
  top: 100px;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    display: ${props => props.opened ? 'block' : 'none'};
    max-width: 100%;
    margin-right: 0;
  }
  
  h4 {
    margin-bottom: 24px;
  }

  ol {
    list-style: none;
    counter-reset: li -1;
    padding: 0;
    margin: 0;
  }

  li {
    margin-bottom: 16px;
    counter-increment: li;
    font-size: 12px;
    line-height: 18px;

    a {
      color: ${props => props.theme.colors.gray1};
      font-weight: 400;

      &:hover {
        opacity: .6;
      }
    }

    &::before {
      color: ${props => props.theme.colors.gray1};
      content: "." counter(li);
      display: inline-block;
      width: 1em;
      margin-right: 0.5em;
      text-align: right;
      direction: rtl;
    }
  }
`;

export const LabelWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    display: none;
  }
`;


